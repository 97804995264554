.app {
  display: grid;
}

.main {
  display: grid;

  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  // padding: 0 24px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}

.policy {
  display: grid;
  gap: 60px;

  padding: 40px 40px;

  @media (max-width: 500px) {
    padding: 16px 16px;
  }
}

.requisites {
  display: grid;
  gap: 60px;

  padding: 40px 40px;
  box-shadow: 0 0 10px 1px rgb(226, 222, 226);
}

.title {
  text-align: center;
  font-size: 32px;
  line-height: 1.1em;

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 1.1em;
  }
}

.policy_content {
  background-color: rgba(255, 255, 255, 0.406);
  padding: 40px 120px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 1px grey;
  border-radius: 4px;
  line-height: 24px;

  p {
    padding: 0;
  }

  @media (max-width: 600px) {
    padding: 20px 30px;
  }
  @media (max-width: 400px) {
    padding: 20px 16px;
  }
}

.single_title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
}

.single_text {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.requisites_content {
  background-color: rgba(255, 255, 255, 0.406);
  padding: 40px 64px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 1px grey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 600px) {
    padding: 20px 30px;
  }
  @media (max-width: 400px) {
    padding: 20px 16px;
  }
}

.row {
  display: grid;
  grid-template-columns: 6fr 1fr 6fr;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    overflow: auto;
  }
}

.row_item {
  border-bottom: 1px solid rgb(137, 173, 188);
}

.under_header {
  height: 634px;
  grid-column: span 6;
  display: grid;
  grid-template-columns: 1fr 1fr;

  background: linear-gradient(to top left, rgb(6, 66, 9), rgb(21, 163, 139));

  color: rgba(255, 255, 255, 1);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.under_header__item {
  display: flex;
  gap: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0px 15px 0 150px;

  @media (max-width: 600px) {
    padding: 24px;
    height: 100%;
  }
}
.under_header__item_1 {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-end;
  // margin: 0 10px;

  // border-radius: 50%;
  overflow: hidden;
  padding: 100px;

  img {
    height: 100%;
    width: auto;
    border-radius: 50%;
  }
}

.under_header__title {
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  @media (max-width: 600px) {
    font-size: 28px;
    justify-content: center;
    align-items: center;
  }
}

.under_header__text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;

  @media (max-width: 600px) {
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
}

.under_header__logo {
  width: 158px;
  margin-right: auto;
}

.border {
  border: 12px solid rgb(91, 150, 94);
  border-radius: 5px;
}
